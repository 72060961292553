<template>
  <div>
    <vs-popup class="sm:popup-w-60" title="Tambah Hadiah" :active="isActive" v-on:update:active="emitModalIsActive">
      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Tgl Terima *</label>
            <flat-pickr class="w-full" v-model="data.tgl"></flat-pickr>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">No. Bukti *</label>
            <vs-input class="w-full" v-model="data.no_bukti"/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Hadiah *</label>
            <vx-input-group>
              <vs-input placeholder="Pilih Hadiah" :value="data.nama_hadiah" @click="isModalHadiahActive = true" readonly/>
              <template slot="append">
                <div class="append-text btn-addon">
                  <vs-button type="filled" icon-pack="feather" icon="icon-search" @click="isModalHadiahActive = true"/>
                </div>
              </template>
            </vx-input-group>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Harga</label>
            <v-money class="w-full" v-model="data.harga_hadiah" readonly/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Qty *</label>
            <vs-input type="number" class="w-full" v-model="data.qty"/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Qty Diterima *</label>
            <vs-input type="number" class="w-full" v-model="data.qty_diterima"/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Nilai</label>
            <v-money class="w-full" :value="nilai" readonly/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Keterangan</label>
            <vs-input class="w-full" v-model="data.keterangan"/>
          </div>
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Gambar * <span class="text-xs opacity-75" v-if="isCompressingGambar">(compressing...)</span></label>
            <vs-input class="w-full" type="file" accept="image/*" v-model="data.gambarTemp" @change="onSelectedGambar"/>
          </div>
        </div>
        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button :disabled="isLoading || isCompressingGambar" @click="save">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">Simpan</span>
            </vs-button>
          </div>
        </div>
      </div>

      <!--modal hadiah-->
      <vs-popup class="sm:popup-w-70 popup-content-no-padding"
                title="Pilih Data Hadiah"
                :active="isModalHadiahActive"
                v-on:update:active="isModalHadiahActive = $event">
        <Hadiah v-if="isActive" :selectable="true" @selected="onSelectedModalHadiah"/>
      </vs-popup>

    </vs-popup>
  </div>
</template>

<script>
import KontrakHadiahRepository from '@/repositories/marketing/kontrak/kontrak-hadiah-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import Hadiah from '@/views/pages/master/hadiah/Hadiah'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import _ from 'lodash'
import { compressImage, convertToFormData } from '@/utilities/common/global-methods'
import VMoney from '@/views/components/v-money/VMoney'

export default {
  name: 'HadiahAdd',
  props: ['isActive'],
  components: {
    VMoney,
    ValidationErrors,
    flatPickr,
    Hadiah
  },
  data () {
    return {
      isLoading: false,
      isModalHadiahActive: false,
      isCompressingGambar: false,
      errors: null,
      data: {}
    }
  },
  computed: {
    nilai () {
      return (this.data.qty || 0) * (this.data.harga_hadiah || 0)
    }
  },
  methods: {
    onSelectedModalHadiah (item) {
      this.data.id_hadiah = item.id
      this.data.nama_hadiah = item.nama
      this.data.harga_hadiah = item.harga
      this.isModalHadiahActive = false
    },

    async onSelectedGambar (event) {
      const file = event.target.files[0]
      if (file) {
        this.isCompressingGambar = true
        const compressed = await compressImage(file)
        this.isCompressingGambar = false
        this.data.gambar = compressed
      }
    },

    save () {
      this.errors = null
      this.isLoading = true
      const params = convertToFormData({
        ...this.data,
        nilai: this.nilai,
        id_kontrak: this.$route.params.idKontrak
      })
      const idKontrak = this.$route.params.idKontrak

      KontrakHadiahRepository.create(idKontrak, params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, [])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
